
<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Home",

  metaInfo: { title: "Home" },

  extends: View,

  mixins: [
    LoadSections([
      "space",
      "hero",
      "alternating-features",
      "features",
      "theme-features",
      "contact-us",
      "pricing-plan",
      "newsletter",
      "info-alt",
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "home",
    },
  },
};
</script>